<template>
  <div class="h-full relative">
    <DialogHeader
      :title="useT('common.settings')"
      size="large"
      show-close
      class="absolute top-0 left-0 z-20 bg-white w-full rounded-tr-[var(--el-dialog-border-radius)]"
      @close="useDialogStore().close('settings')"
    />
    <div class="h-full overflow-auto">
      <div class="py-16">
        <Form
          ref="formRef"
          :model="form"
          :rules="rules"
          @submit="onSubmit"
        >
          <div class="p-4 sm:p-6">
            <InputBlock
              :label="useT('workspace.domain')"
              direction="horizontal"
              label-stretch
            >
              <TextInput
                :model-value="useRequestURL().host"
                disabled
                class="max-w-lg"
              />
            </InputBlock>
            <InputBlock
              :label="useT('workspace.companyName')"
              prop="name"
              direction="horizontal"
              label-stretch
            >
              <TextInput
                v-model="form.name"
                name="name"
                class="max-w-lg"
              />
            </InputBlock>

            <InputBlock
              :label="useT('workspace.primaryColor')"
              :tooltip-text="useT('workspace.primaryColor.hint')"
              prop="identity.primaryColor"
              direction="horizontal"
              label-stretch
            >
              <ColorPicker
                v-model="form.identity.primaryColor"
                data-cy="primaryColor"
                class="w-full sm:w-auto"
              />
            </InputBlock>

            <div class="py-4 border-t border-gray-200">
              <InputBlock
                :label="useT('workspace.logo')"
                prop="identity.logo"
                :tooltip-text="useT('workspace.logo.hint')"
                direction="horizontal"
                label-stretch
                class="mb-0"
              >
                <ImageUpload
                  v-model="form.identity.logo"
                  destination="workspace"
                  data-cy="logo"
                  class="w-full sm:w-auto"
                  removable
                  @update:model-value="onLogoChanged"
                />
              </InputBlock>
            </div>

            <div class="py-4 border-t border-gray-200">
              <InputBlock
                :label="useT('workspace.font')"
                prop="identity.font"
                :tooltip-text="useT('workspace.font.hint')"
                direction="horizontal"
                label-stretch
                class="mb-0"
              >
                <FontUpload
                  v-model="form.identity.font"
                  data-cy="font"
                  destination="workspace"
                  @update:model-value="onFontChanged"
                />
              </InputBlock>
            </div>
          </div>
        </Form>
      </div>
    </div>

    <div class="border-t px-4 sm:px-6 py-5 absolute left-0 bottom-0 w-full bg-white">
      <ButtonGroup
        type="primary"
        :text-submit="useT('common.save')"
        :text-cancel="useT('poly.close')"
        :loading="isSubmitting"
        @cancel="useDialogStore().close('settings')"
        @submit="formRef.onSubmit();"
      />
    </div>
  </div>
</template>

<script setup>
const formRef = ref(null);
const form = ref(_defaultsDeep(_cloneDeep(useWorkspaceStore().$state), {
  name: '',
  identity: {
    logo: '',
    primaryColor: '',
    font: {
      regular: '',
      medium: '',
      bold: '',
    },
  },
}));
const isSubmitting = ref(false);

const rules = {
  name: [useFormRules().required],
};

// Methods
const onSubmit = async() => {
  isSubmitting.value = true;

  const isColorChanged = form.value?.identity?.primaryColor !== useWorkspaceStore().identity?.primaryColor;

  const response = await useWorkspaceStore().updateWorkspace({
    name: form.value.name,
    identity: {
      primaryColor: form.value.identity.primaryColor,
    },
  });

  isSubmitting.value = false;

  if (response) {
    if (isColorChanged) {
      useToast(useT('workspace.primaryColor.changed'));
    } else {
      useToast(useT('common.changesSaved'));
    }

    useDialogStore().close('settings');

    await useWorkspaceStore().fetchWorkspace();
  }
};

const onLogoChanged = async(logo) => {
  const response = await useWorkspaceStore().updateWorkspace({
    identity: {
      logo,
    },
  });

  if (response) {
    useToast(useT('workspace.logo.changed'));
  }
};

const onFontChanged = async(font) => {
  const response = await useWorkspaceStore().updateWorkspace({
    identity: {
      font,
    },
  });

  if (response) {
    if (font) {
      useToast(useT('workspace.font.changed'));
    } else {
      useToast(useT('workspace.font.removed'));
    }
  }
};
</script>
