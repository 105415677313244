<template>
  <div class="border border-gray-200 rounded-xl py-5 flex flex-col justify-between">
    <div class="relative">
      <div class="px-4 sm:px-6">
        <h3
          class="text-lg font-semibold"
        >
          {{ props.tier.title }}
        </h3>
        <div
          v-if="pricePerGuest"
          class="text-gray-600 text-sm flex items-center"
        >
          {{ useT('plan.xPerGuest', { value: useFormatPrice(pricePerGuest, useWorkspaceStore().getCurrency) }) }}
          <Tooltip
            class="ml-1"
            :text="useT('plan.price.priceExclVat')"
          />
        </div>
        <Badge
          v-if="props.isCurrent"
          :text="useT('plan.current')"
          type="info"
          class="absolute top-1 right-6"
        />
        <Badge
          v-if="props.tier.isPopular"
          :text="useT('plan.mostPopular')"
          type="success"
          class="absolute top-1 right-6"
        />
        <hr class="mt-4">
      </div>
      <div
        class="px-6 pt-4 relative"
      >
        <ul class="pb-3">
          <li
            v-for="feature in props.tier.features"
            :key="feature"
            class="mb-1.5 flex"
          >
            <div
              v-if="feature.number"
              class="text-base font-medium min-w-5 w-5 h-5 flex items-center justify-center mr-3"
            >
              {{ feature.number }}
            </div>
            <Icon
              v-else
              name="check"
              class="mr-3 text-gray-500"
            />
            <span
              class="text-sm text-gray-900"
              :class="feature.isBold ? 'font-bold' : ''"
            >
              {{ feature.title }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="px-6">
      <Button
        :type="tier.isButtonRegular ? 'regular-outline' : 'primary'"
        class="w-full"
        :data-cy="`select-tier-${tier.name}`"
        :loading="!pricePerGuest"
        :disabled="props.isDisabled"
        @click="emit('set-tier', tier.name)"
      >
        {{ props.isCurrent ? useT('plan.increaseCapacity') : useT('plan.select') }}
      </Button>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  tier: {
    type: Object,
    required: true,
  },
  isCurrent: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['set-tier']);

// Computed
const pricePerGuest = computed(() => useProductsStore().getProductPrice(`single_use_${props.tier.name}`));
</script>
