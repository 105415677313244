<template>
  <div>
    <Dialog
      v-model="useDialogStore().passwordChange.isOpen"
      size="small-hug"
    >
      <template #header="{ close }">
        <DialogHeader
          :title="useT('user.changePassword')"
          :show-close="true"
          @close="close"
        />
      </template>

      <Form
        ref="formRef"
        :model="form"
        :rules="rules"
        @submit="onSubmit"
      >
        <InputBlock
          :label="useT('user.currentPassword')"
          prop="oldPassword"
        >
          <TextInput
            v-model="form.oldPassword"
            type="password"
            name="oldPassword"
            :placeholder="useT('user.newPassword.placeholder')"
            autocomplete="current-password"
            data-private
          />
        </InputBlock>

        <InputBlock
          :label="useT('user.newPassword')"
          prop="password"
        >
          <TextInput
            v-model="form.password"
            type="password"
            name="password"
            :placeholder="useT('user.newPassword.placeholder')"
            autocomplete="new-password"
            data-private
            @focus="isPasswordFocus = true"
            @blur="isPasswordFocus = false"
          />

          <client-only>
            <el-collapse-transition>
              <UserPasswordStrength
                v-show="isPasswordFocus"
                :password="form.password"
                :rules="rules.password"
              />
            </el-collapse-transition>
          </client-only>
        </InputBlock>

        <InputBlock
          :label="useT('user.repeatPassword')"
          prop="passwordConfirm"
          class="mb-0"
        >
          <TextInput
            v-model="form.passwordConfirm"
            type="password"
            name="passwordConfirm"
            :placeholder="useT('user.newPassword.placeholder')"
            autocomplete="new-password"
          />
        </InputBlock>
      </Form>

      <template
        #footer
      >
        <ButtonGroup
          type="primary"
          :text-submit="useT('user.changePassword')"
          :text-cancel="useT('poly.cancel')"
          :loading="isLoading"
          @submit="formRef.onSubmit()"
          @cancel="useDialogStore().close('passwordChange')"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
// Refs
const form = ref({
  oldPassword: '',
  password: '',
  passwordConfirm: '',
});

const rules = {
  oldPassword: [useFormRules().required],
  password: useFormRules().password,
  passwordConfirm: useFormRules().passwordConfirm(computed(() => form.value.password)),
};

const isLoading = ref(false);
const formRef = ref(null);
const isPasswordFocus = ref(false);

// Methods
const onSubmit = async() => {
  isLoading.value = true;

  const response = await useUsersStore().updateUserMePassword({
    oldPassword: form.value.oldPassword,
    newPassword: form.value.password,
  });

  isLoading.value = false;

  if (response) {
    useToast(useT('user.password.changed'));
    useDialogStore().close('passwordChange');

    form.value = {
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    };
  }
};
</script>
