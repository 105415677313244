<template>
  <Dialog
    v-model="useDialogStore().workspaceAirtable.isOpen"
    size="small-hug"
    @open="onOpen"
  >
    <template #header>
      <DialogHeader
        :title="useT('workspace.integrations.airtable.title')"
        show-close
        @close="useDialogStore().close('workspaceAirtable')"
      />
    </template>

    <Form
      ref="formRef"
      v-loading="isLoading"
      :model="form"
      :rules="rules"
      @submit="onSubmit"
    >
      <InputBlock
        :label="useT('workspace.airtable.apiKey')"
        prop="apiKey"
      >
        <TextInput
          v-model="form.apiKey"
          name="apiKey"
          :placeholder="useT('workspace.airtable.apiKey.placeholder')"
        />
      </InputBlock>

      <InputBlock
        :label="useT('workspace.airtable.baseId')"
        prop="baseId"
      >
        <TextInput
          v-model="form.baseId"
          name="baseId"
          :placeholder="useT('workspace.airtable.baseId.placeholder')"
        />
      </InputBlock>

      <InputBlock
        :label="useT('workspace.airtable.tableId')"
        prop="baseId"
        class="mb-0"
      >
        <WorkspaceAirtableTableSelect
          v-model:table-id="form.tableId"
          :api-key="form.apiKey"
          :base-id="form.baseId"
          name="tableId"
        />
      </InputBlock>

      <div
        v-if="airtable?.isEnabled"
        class="pt-4"
      >
        <Button
          type="danger-text"
          icon-left="trash-01"
          @click="() => useModalStore().open({
            type: 'danger',
            title: useT('workspace.airtable.confirmDelete'),
            description: useT('common.delete.irreversible'),
            submitButtonText: useT('common.remove'),
            cancelButtonText: useT('poly.cancel'),
            onSubmit: onRemoveConfirmed,
          })"
        >
          {{ useT('workspace.airtable.delete') }}
        </Button>
      </div>
    </Form>

    <template #footer>
      <ButtonGroup
        type="primary"
        :text-submit="useT('common.save')"
        :text-cancel="useT('poly.close')"
        :loading="isSubmitting"
        @cancel="useDialogStore().close('workspaceAirtable')"
        @submit="formRef.onSubmit()"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const formRef = ref(null);
const isLoading = ref(false);
const isSubmitting = ref(false);
const airtable = ref(null);
const form = ref({
  apiKey: '',
  baseId: '',
  tableId: '',
});

const rules = {
  apiKey: [useFormRules().required],
  baseId: [useFormRules().required],
  tableId: [useFormRules().required],
};

// Methods
const onOpen = async() => {
  isLoading.value = true;
  airtable.value = null;
  airtable.value = await useWorkspaceStore().getAirtable();
  isLoading.value = false;

  form.value = {
    apiKey: airtable.value?.apiKey || '',
    baseId: airtable.value?.baseId || '',
    tableId: airtable.value?.tableId || '',
  };
};

const onSubmit = async() => {
  isSubmitting.value = true;

  const response = await useWorkspaceStore().updateAirtable({
    isEnabled: Boolean(form.value.apiKey && form.value.baseId && form.value.tableId),
    ...form.value,
  });

  isSubmitting.value = false;

  if (response) {
    useToast(useT('common.changesSaved'));
    useWorkspaceStore().fetchWorkspace();
    useDialogStore().close('workspaceAirtable');
    useDialogStore().workspaceAirtable.onSubmit(response);
  }
};

const onRemoveConfirmed = () => {
  form.value = {
    apiKey: null,
    baseId: null,
  };

  onSubmit();
};
</script>
