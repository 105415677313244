<template>
  <Dialog
    v-model="useDialogStore().userInvite.isOpen"
    size="small-hug"
    @open="onOpen"
  >
    <template #header="{ close }">
      <DialogHeader
        :title="useT('user.add')"
        :show-close="true"
        @close="close"
      />
    </template>

    <Form
      ref="formRef"
      :model="form"
      :rules="rules"
      @submit="onSubmit"
    >
      <div class="flex items-start gap-4 mb-2">
        <InputBlock
          :label="useT('poly.email')"
          prop="email"
        >
          <TextInput
            v-model="form.email"
            type="email"
            name="email"
            :placeholder="useT('poly.email')"
          />
        </InputBlock>

        <InputBlock
          :label="useT('user.role')"
          prop="role"
          class="w-48"
        >
          <Select
            v-model="form.role"
            :options="useUsersStore().roles.map((item) => ({ id: item, text: useT(`users.role.${item}.title`), description: useT(`users.role.${item}.description`) }))"
          />
        </InputBlock>
      </div>

      <InputBlock
        prop="events"
        :label="useT('user.addToEvents')"
      >
        <Select
          v-model="form.events"
          :options="events.map((item) => ({ id: item._id, text: item.general?.title, thumbnail: item.general?.thumbnail }))"
          :placeholder="useT('user.addToEvents.select')"
          multiple
        />
      </InputBlock>
    </Form>

    <template
      #footer
    >
      <ButtonGroup
        type="primary"
        :text-submit="useT('user.sendInvite')"
        :text-cancel="useT('poly.cancel')"
        icon="send-01"
        align="right"
        :loading="isLoading"
        @submit="formRef.onSubmit()"
        @cancel="useDialogStore().close('userInvite')"
      />
    </template>
  </Dialog>
</template>

<script setup>
// Refs
const form = ref({
  email: '',
  role: 'creator',
  events: [],
});

const rules = {
  email: [
    useFormRules().required,
    useFormRules().email,
  ],
  role: [
    useFormRules().required,
  ],
};

const isLoading = ref(false);
const formRef = ref(null);
const events = ref([]);

// Methods
const onOpen = async() => {
  form.value = {
    email: '',
    role: 'creator',
    events: [],
  };

  const response = await useEventsStore().getEvents();

  if (response?.results) {
    events.value = response.results.filter((event) => {
      if (event?.plan?.config?.tier === 'basic') {
        return false;
      }

      if (event?.plan?.config?.tier === 'professional' && event?.collaborators?.length >= 4) {
        return false;
      }

      return true;
    });
  }
};

const onSubmit = async() => {
  isLoading.value = true;

  const response = await useUsersStore().sendUserInvite(form.value);

  isLoading.value = false;

  if (response) {
    useToast(useT('user.invite.success'));
    useDialogStore().close('userInvite');
    useDialogStore().userInvite.onSubmit(response);
  }
};
</script>
