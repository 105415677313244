<template>
  <div v-loading="isLoading">
    <div
      v-if="apiKeys.length"
      class="flex flex-col shadow-sm rounded-lg divide-y divide-gray-200 mb-5"
    >
      <transition-group
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="transform opacity-0 translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition duration-300 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform opacity-0"
      >
        <div
          v-for="apiKey in apiKeys"
          :key="apiKey._id"
          class="flex items-center justify-between border-l border-r border-t border-gray-200 first:rounded-t-lg last:rounded-b-lg p-3"
        >
          <div class="flex flex-col gap-0.5 flex-1 max-w-xs">
            <div
              class="flex items-center gap-2"
              :class="apiKey.isRevealed ? 'mb-1' : 'mb-0'"
            >
              <TextInput
                v-if="apiKey.isRevealed"
                v-model="apiKey.value"
                disabled
              />
              <span
                v-else
                class="text-sm font-semibold text-gray-700"
              >
                {{ apiKey.value }}
              </span>
              <Button
                v-if="apiKey.isRevealed"
                type="regular-outline"
                content-icon="copy-03"
                class="shrink-0"
                @click="() => {
                  useClipboard().copy(apiKey.value);
                  useToast(useT('common.copied'));
                }"
              />
            </div>
            <span class="text-xs text-gray-500">
              {{ useT('workspace.integrations.apiKeys.createdBy') }} {{ apiKey.createdBy?.fullName || '' }}
              {{ useT('workspace.integrations.apiKeys.createdOn') }}
              {{ getFormattedDate(
                apiKey.createdAt,
                {
                  lang: useAuthStore()?.user?.locale,
                  langVersion: 'browser',
                  format: 'lll',
                },
              ) }}
            </span>
          </div>
          <Button
            type="regular-text"
            content-icon="trash-01"
            @click="deleteApiKey(apiKey._id)"
          />
        </div>
      </transition-group>
    </div>
    <EmptyState
      v-else
      icon="key-01"
      :title="useT('workspace.integrations.apiKeys.noApiKeys')"
    >
      <Button
        type="primary"
        icon-left="key-01"
        :loading="isGenerating"
        @click="createApiKey"
      >
        {{ useT('workspace.integrations.apiKeys.generate') }}
      </Button>
    </EmptyState>

    <div v-if="apiKeys.length">
      <Button
        type="primary"
        icon-left="key-01"
        :loading="isGenerating"
        @click="createApiKey"
      >
        {{ useT('workspace.integrations.apiKeys.generate') }}
      </Button>
    </div>
  </div>
</template>


<script setup>
// Refs
const isLoading = ref(false);
const isGenerating = ref(false);
const apiKeys = ref([]);

// Methods
const createApiKey = async() => {
  isGenerating.value = true;

  const response = await useWorkspaceStore().createApiKey();

  if (response) {
    apiKeys.value = [
      ...apiKeys.value,
      {
        ...response,
        isRevealed: true,
      },
    ];

    useToast(useT('workspace.integrations.apiKeys.created'));
  }

  isGenerating.value = false;
};

const deleteApiKey = (id) => {
  useModalStore().open({
    type: 'danger',
    title: useT('workspace.integrations.apiKeys.confirmDelete'),
    description: useT('common.delete.irreversible'),
    submitButtonText: useT('common.remove'),
    cancelButtonText: useT('poly.cancel'),
    onSubmit: async() => {
      isLoading.value = true;

      await useWorkspaceStore().deleteApiKey(id);

      useToast(useT('workspace.attenduApiKeys.removed'));

      apiKeys.value = apiKeys.value.filter((apiKey) => apiKey._id !== id);

      isLoading.value = false;
    },
  });
};

// Lifecycle
onMounted(async() => {
  isLoading.value = true;

  apiKeys.value = [];
  apiKeys.value = await useWorkspaceStore().getApiKeys();

  isLoading.value = false;
});
</script>
