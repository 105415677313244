<template>
  <Dialog
    v-model="useDialogStore().workspaceAttenduApiKeys.isOpen"
    size="small-hug"
  >
    <template #header>
      <DialogHeader
        :title="useT('workspace.integrations.apiKeys.title')"
        show-close
        @close="useDialogStore().close('workspaceAttenduApiKeys')"
      />
    </template>

    <WorkspaceAttenduApiKeys />
  </Dialog>
</template>

<script setup>
</script>
