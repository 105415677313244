<template>
  <Dialog
    v-model="useDialogStore().planUpgrade.isOpen"
    size="medium-hug"
    @open="onOpen"
  >
    <template #header>
      <DialogHeader
        :title="useT('plan.doUpgradePlan')"
        show-close
        @close="useDialogStore().close('planUpgrade')"
      />
    </template>

    <div>
      <PlanTiers
        :current-plan="plan"
        @set-tier="(tier) => useDialogStore().open('planOrder', { tier, currentPlan: plan })"
      />
    </div>
  </Dialog>
</template>

<script setup>
// Computed
const plan = computed(() => useDialogStore().planUpgrade.plan);

// Methods
const onOpen = async() => {

};
</script>
