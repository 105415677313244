<template>
  <div
    class="shadow-sm border border-gray-200 rounded-lg"
    :class="props.clickable ? 'group cursor-pointer ' : ''"
  >
    <div class="flex items-center p-3 ml-8">
      <Avatar
        size="md"
        type="square"
        :icon="props.icon"
        class="border-none"
        :class="props.isActive ? 'bg-primary-100 [&_svg]:text-primary-500' : ''"
      />
      <div class="flex-1 mx-3">
        <div class="text-xs text-gray-600">
          {{ useT('plan.addon') }}
        </div>
        <div class="text-sm font-medium text-gray-900">
          {{ props.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  icon: {
    type: String,
    default: 'package',
  },
  title: {
    type: String,
    default: '',
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});
</script>
