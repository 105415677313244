<template>
  <div
    v-loading="!useProductsStore().prices"
    class="grid grid-cols-1 xl:grid-cols-3 gap-3 md:gap-4 overflow-auto w-full"
  >
    <PlanTier
      v-for="tier in tiers"
      :key="tier.name"
      class="h-full"
      :class="useProductsStore().prices ? '' : 'opacity-0'"
      :tier="tier"
      :is-current="props.currentPlan?.config.tier === tier.name"
      :is-disabled="isDisabled(tier)"
      @set-tier="(payload) => emit('set-tier', payload)"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  currentPlan: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['set-tier']);

// Computed
const isDisabled = (tier) => {
  const tierIndex = tiers.value.findIndex(({ name }) => name === tier.name);
  const currentTierIndex = tiers.value.findIndex(({ name }) => name === props.currentPlan?.config.tier);

  return tierIndex < currentTierIndex;
};

const tiers = computed(() => ([
  {
    name: 'basic',
    title: useT('plan.tier.basic'),
    isButtonRegular: !props.currentPlan,
    features: [
      {
        title: useT('plan.features.noCollaborators'),
      },
      {
        title: useT('plan.features.guestList'),
      },
      {
        title: useT('plan.features.mailing'),
      },
      {
        title: useT('event.checkIn.title'),
      },
      {
        title: useT('event.analytics.title'),
      },
      {
        title: useT('plan.features.emailSupport'),
      },
    ],
  },
  {
    name: 'professional',
    title: useT('plan.tier.professional'),
    isPopular: !props.currentPlan,
    features: [
      {
        title: useT('plan.tiers.featuresBasic'),
        isBold: true,
      },
      {
        title: useT('plan.features.xCollaborators', { count: 3 }),
      },
      {
        title: useT('plan.features.emailBuilder'),
      },
      {
        title: useT('plan.features.pageBuilder'),
      },
      {
        title: useT('plan.features.multilingual'),
      },
      {
        title: useT('event.checkIn.badgePrinting.title'),
      },
    ],
  },
  {
    name: 'enterprise',
    title: useT('plan.tier.enterprise'),
    features: [
      {
        title: useT('plan.tiers.featuresProfessional'),
        isBold: true,
      },
      {
        title: useT('plan.tiers.unlimitedCollaborators'),
      },
      {
        title: useT('event.guestApp.title'),
      },
      {
        title: useT('event.onlineEvent'),
      },
      {
        title: useT('event.checkIn.zones.title'),
      },
      {
        title: useT('plan.features.customDomain'),
      },
      {
        title: useT('workspace.integrations'),
      },
    ],
  },
]));

// Lifecycle
onMounted(async() => {
  if (!useProductsStore().prices) {
    await useProductsStore().fetchPrices();
  }
});
</script>
